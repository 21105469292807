// import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import BellIcon from "../../../Icons/BellIcon";
import MenuIcon from "../../../Icons/MenuIcon";
// import Profile from "../../../public/image/profilePic.png";
import { RootState } from "../../../Store/Store";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import classes from "./Header.module.scss";

export interface IHeaderProps {
  title?: string;
  menuCollapse: boolean;
  setmenuCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}
const Header: React.FC<IHeaderProps> = (props): JSX.Element => {
  const { width } = useWindowDimensions();
  const [notificationOn, setNotificationOn] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const userData = useSelector((state: RootState) => state.auth.user.profile);

  useEffect(() => {
    if (width && width <= 1200) {
      setShowMenuIcon(false);
    } else {
      setShowMenuIcon(true);
    }
  }, [width]);
  return (
    <div className={`${classes.header} bg-others-background z-20`}>
      <div
        className={`flex justify-center items-center ${
          !props.menuCollapse ? "pl-[300px]" : "pl-[60px]"
        }`}
      >
        {showMenuIcon && (
          <span
            className="cursor-pointer"
            role="button"
            aria-hidden="true"
            onClick={() => props.setmenuCollapse(!props.menuCollapse)}
          >
            <MenuIcon />
          </span>
        )}
        <span className="text-xl xl:text-h6 pl-3 cursor-default font-semibold">
          {props.title}
        </span>
      </div>

      <div className={`${classes.menu_separates}`}>
        <div className="shadow-md border-1 rounded-full p-2 cursor-pointer">
          <BellIcon />
        </div>
        <div
          className="text-smalltext"
          title="profile"
          role="button"
          aria-hidden="true"
          onClick={() => {
            setShowSettings(!showSettings);
          }}
        >
          {`${userData?.first_name} ${userData?.last_name}`}
        </div>
        {/* <div
          className="cursor-pointer"
          role="button"
          aria-hidden="true"
          onClick={() => {
            setShowSettings(!showSettings);
          }}
        >
          <Image
            src={userData?.avatar || Profile}
            className="w-[40px] h-[40px]"
            width="40"
            height="40"
            alt="Profile Pic"
          />
        </div> */}
      </div>
      {showSettings && (
        <div className="bg-white absolute w-[377px] h-[125px] rounded-md shadow-2xl right-10 top-[60px] flex flex-col justify-between p-8 font-normal text-others-black text-body1">
          <div className="flex justify-between items-center">
            <span>Notification</span>
            <span>
              <ToggleSwitch
                on={notificationOn}
                onClick={() => setNotificationOn(!notificationOn)}
              />
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span>Communication Mode</span>
            <span>Email</span>
          </div>
        </div>
      )}
    </div>
  );
};

Header.defaultProps = {
  title: undefined,
};

export default Header;
