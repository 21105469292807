/* eslint-disable max-len */
import React from "react";

const IntuChargeLogoLight: React.FC = () => (
  <svg
    width="160"
    height="45"
    viewBox="0 0 182 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.9826 40.8979C30.1845 40.8979 26.4464 39.2842 23.7683 36.0569C21.0903 32.8296 19.7513 27.8588 19.7513 21.1445C19.7513 14.3931 21.0903 9.42228 23.7683 6.23206C26.4464 3.00474 30.1845 1.39109 34.9826 1.39109C37.6606 1.39109 40.0597 1.91042 42.1798 2.9491C44.2999 3.98778 45.9737 5.60144 47.2011 7.79008C48.4286 9.97872 49.0423 12.798 49.0423 16.2479H41.7335C41.7335 13.2802 41.1012 11.0916 39.8365 9.68195C38.6091 8.23523 36.9911 7.51186 34.9826 7.51186C32.3418 7.51186 30.3704 8.60618 29.0686 10.7948C27.7668 12.9464 27.1159 15.9326 27.1159 19.7534V22.5356C27.1159 26.3564 27.7854 29.3612 29.1244 31.5498C30.4634 33.7014 32.4905 34.7771 35.2058 34.7771C37.2887 34.7771 38.9439 34.0723 40.1713 32.6627C41.4359 31.216 42.0682 29.0273 42.0682 26.0968H49.0423C49.0423 29.4354 48.4286 32.199 47.2011 34.3876C45.9737 36.5763 44.2999 38.2085 42.1798 39.2842C40.0969 40.36 37.6978 40.8979 34.9826 40.8979Z"
      fill="white"
    />
    <path
      d="M55.5053 40.2302V0H62.2004V14.0778H62.4236C63.0559 13.1504 63.9671 12.2972 65.1574 11.5182C66.3476 10.7021 67.7982 10.294 69.5092 10.294C71.7781 10.294 73.6378 10.9988 75.0884 12.4085C76.5762 13.8181 77.3201 16.2479 77.3201 19.6978V40.2302H70.625V21.534C70.625 19.6421 70.2717 18.3067 69.565 17.5277C68.8955 16.7487 67.9656 16.3592 66.7754 16.3592C65.4735 16.3592 64.3763 16.9341 63.4836 18.0841C62.6281 19.197 62.2004 20.5695 62.2004 22.2017V40.2302H55.5053Z"
      fill="white"
    />
    <path
      d="M90.0977 40.8979C89.4282 40.8979 88.6657 40.7866 87.8102 40.564C86.9547 40.3415 86.1364 39.9334 85.3553 39.3399C84.6115 38.7464 83.9791 37.9117 83.4584 36.8359C82.9749 35.7602 82.7331 34.3691 82.7331 32.6627C82.7331 30.0289 83.3468 27.9701 84.5742 26.4863C85.8389 25.0024 87.5684 23.9638 89.7629 23.3702C91.9574 22.7396 94.4681 22.4243 97.2949 22.4243V19.3639C97.2949 18.3623 97.1089 17.5091 96.737 16.8043C96.365 16.0995 95.5282 15.7471 94.2263 15.7471C92.8501 15.7471 91.9202 16.0809 91.4367 16.7487C90.9532 17.4164 90.7114 18.1027 90.7114 18.8075V19.6421H84.1837C84.1465 19.4566 84.1279 19.2712 84.1279 19.0857C84.1279 18.9002 84.1279 18.6776 84.1279 18.418C84.1279 15.9697 85.0206 14.0036 86.8059 12.5198C88.6285 11.0359 91.0462 10.294 94.059 10.294C97.4809 10.294 99.9915 11.0545 101.591 12.5754C103.228 14.0592 104.046 16.1737 104.046 18.9188V33.3304C104.046 34.0352 104.213 34.536 104.548 34.8328C104.883 35.1295 105.236 35.2779 105.608 35.2779H106.947V40.0076C106.575 40.1931 106.073 40.3786 105.441 40.564C104.808 40.7866 103.99 40.8979 102.986 40.8979C101.647 40.8979 100.531 40.5455 99.6382 39.8407C98.7455 39.1359 98.1318 38.2085 97.797 37.0585H97.5181C96.6998 38.2085 95.7513 39.1359 94.6727 39.8407C93.594 40.5455 92.069 40.8979 90.0977 40.8979ZM92.8873 35.3336C93.966 35.3336 94.8215 35.0368 95.4538 34.4433C96.0861 33.8497 96.551 33.0707 96.8486 32.1062C97.1461 31.1418 97.2949 30.0845 97.2949 28.9346V27.2653C95.9931 27.2653 94.7471 27.4137 93.5568 27.7104C92.4038 27.9701 91.4553 28.4523 90.7114 29.1571C89.9675 29.8249 89.5956 30.7894 89.5956 32.0506C89.5956 33.0522 89.8559 33.8497 90.3767 34.4433C90.8974 35.0368 91.7343 35.3336 92.8873 35.3336Z"
      fill="white"
    />
    <path
      d="M112.03 40.2302V10.9618H117.609L118.39 15.2463H118.725C119.134 14.0221 119.71 12.8907 120.454 11.852C121.198 10.8134 122.314 10.294 123.802 10.294C124.657 10.294 125.327 10.3682 125.81 10.5166C126.331 10.665 126.591 10.7392 126.591 10.7392V17.1938H124.192C123.225 17.1938 122.314 17.3978 121.459 17.8059C120.64 18.2139 119.971 18.9002 119.45 19.8647C118.966 20.8292 118.725 22.1832 118.725 23.9267V40.2302H112.03Z"
      fill="white"
    />
    <path
      d="M171.08 40.8979C167.324 40.8979 164.46 39.6737 162.488 37.2254C160.554 34.74 159.587 30.8635 159.587 25.596C159.587 20.3284 160.554 16.4704 162.488 14.0221C164.46 11.5367 167.249 10.294 170.857 10.294C174.391 10.294 177.031 11.5367 178.78 14.0221C180.565 16.4704 181.458 20.3284 181.458 25.596V27.2653H166.561C166.598 29.862 166.952 31.8837 167.621 33.3304C168.291 34.74 169.444 35.4449 171.08 35.4449C172.233 35.4449 173.145 34.9626 173.814 33.9981C174.484 32.9965 174.818 31.624 174.818 29.8805H181.458C181.458 33.6272 180.472 36.4093 178.501 38.227C176.529 40.0076 174.056 40.8979 171.08 40.8979ZM166.617 22.4243H174.428C174.428 20.3098 174.13 18.6776 173.535 17.5277C172.977 16.3406 172.084 15.7471 170.857 15.7471C169.444 15.7471 168.402 16.3406 167.733 17.5277C167.1 18.6776 166.728 20.3098 166.617 22.4243Z"
      fill="white"
    />
    <path
      d="M1.43333 38.6919C1.14986 38.9755 1.00812 39.3537 1.00812 39.8147C1.00812 40.2756 1.14986 40.642 1.43333 40.9257C1.71681 41.2094 2.08296 41.3512 2.54361 41.3512L15.6425 41.3512C16.1032 41.3512 16.4694 41.2094 16.7528 40.9257C17.0363 40.642 17.178 40.2756 17.178 39.8147C17.178 39.3537 17.0363 38.9874 16.7528 38.6919C16.4694 38.4082 16.1032 38.2546 15.6425 38.2546L2.54361 38.2546C2.09477 38.2546 1.71681 38.3964 1.43333 38.6919Z"
      fill="#50C878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2254 8.34637C17.2254 11.7148 14.6269 14.4569 11.426 14.4569L2.37837 14.4569C1.61063 14.4569 0.984619 13.8305 0.984619 13.0622C0.984619 12.294 1.61063 11.6676 2.37837 11.6676L11.426 11.6676C13.0914 11.6676 14.4379 10.1783 14.4379 8.33455C14.4379 6.50257 13.0796 5.01335 11.426 5.01335L2.37837 5.01335C1.61063 5.01335 0.984619 4.38693 0.984619 3.61868C0.984619 2.85043 1.61063 2.22401 2.37837 2.22401L11.426 2.22401C14.6269 2.22401 17.2254 4.96607 17.2254 8.34637Z"
      fill="#50C878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.249 17.4709C17.249 20.8394 14.6387 23.6169 11.426 23.6169L2.37837 23.6169C1.61063 23.6169 0.984619 22.9905 0.984619 22.2222C0.984619 21.454 1.61063 20.8275 2.37837 20.8275L4.07923 20.8275L4.07923 19.9766C4.07923 19.1256 4.77611 18.4282 5.62653 18.4282C6.47696 18.4282 7.17384 19.1256 7.17384 19.9766L7.17384 20.8275L11.426 20.8275C13.0678 20.8275 14.4615 19.2674 14.4615 17.4591C14.4615 16.9863 14.367 16.5372 14.2135 16.1235C15.0757 15.7335 15.8553 15.1661 16.5049 14.457C16.9774 15.3553 17.249 16.3835 17.249 17.4709Z"
      fill="#50C878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.80756 24.8251L15.8552 24.8251C16.6229 24.8251 17.2489 25.4515 17.2489 26.2198C17.2489 26.988 16.6229 27.6145 15.8552 27.6145L6.80756 27.6145C5.14214 27.6145 3.79563 29.1037 3.79563 30.9357C3.79563 32.7676 5.15395 34.2569 6.80756 34.2569L15.8552 34.2569C16.6229 34.2569 17.2489 34.8833 17.2489 35.6515C17.2489 36.4198 16.6229 37.0462 15.8552 37.0462L6.80756 37.0462C3.60664 37.0462 1.00812 34.3041 1.00812 30.9357C1.00812 27.5672 3.61846 24.8251 6.80756 24.8251Z"
      fill="#50C878"
    />
    <path
      d="M137.494 38.4904C137.494 42.0854 140.408 45.0002 144.003 45.0002C147.598 45.0002 150.513 42.0854 150.513 38.4904C150.513 34.8953 147.598 31.9805 144.003 31.9805C140.408 31.9805 137.494 34.8953 137.494 38.4904Z"
      fill="#50C878"
    />
    <path
      d="M143.806 31.9819C150.996 31.9819 156.825 26.1523 156.825 18.9622H143.806L143.806 31.9819Z"
      fill="#50C878"
    />
    <path
      d="M130.787 18.9618C130.787 26.1519 136.616 31.9816 143.806 31.9816L143.806 18.9618H130.787Z"
      fill="#50C878"
    />
    <path
      d="M143.806 18.9631H156.825C156.825 11.773 150.996 5.94336 143.806 5.94336L143.806 18.9631Z"
      fill="#50C878"
    />
    <path
      d="M130.787 18.9631H143.806L143.806 5.94336C136.616 5.94336 130.787 11.773 130.787 18.9631Z"
      fill="#50C878"
    />
    <path
      d="M148.248 18.4521L144.253 27.7731C144.191 27.9119 144.083 28.0253 143.948 28.0946C143.813 28.1639 143.658 28.1851 143.509 28.1545C143.36 28.1239 143.226 28.0435 143.129 27.9265C143.032 27.8095 142.977 27.6628 142.975 27.5108V21.5187H139.646C139.536 21.5181 139.428 21.4904 139.331 21.4381C139.234 21.3857 139.152 21.3102 139.092 21.2184C139.031 21.1266 138.994 21.0213 138.984 20.9118C138.974 20.8023 138.991 20.6919 139.033 20.5906L143.028 11.2695C143.088 11.1282 143.196 11.012 143.332 10.9407C143.468 10.8694 143.624 10.8473 143.775 10.8782C143.925 10.9091 144.06 10.991 144.157 11.1101C144.254 11.2293 144.307 11.3782 144.307 11.5318V17.5239H147.635C147.745 17.5245 147.854 17.5522 147.95 17.6046C148.047 17.6569 148.129 17.7324 148.19 17.8242C148.251 17.916 148.288 18.0213 148.298 18.1308C148.308 18.2404 148.291 18.3507 148.248 18.4521Z"
      fill="white"
    />
  </svg>
);

export default IntuChargeLogoLight;
