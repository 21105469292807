/* eslint-disable max-len */
import React from "react";

const StationsIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9.45455C19 14.8636 11.5 19.5 11.5 19.5C11.5 19.5 4 14.8636 4 9.45455C4 7.61009 4.79018 5.84117 6.1967 4.53694C7.60322 3.23271 9.51088 2.5 11.5 2.5C13.4891 2.5 15.3968 3.23271 16.8033 4.53694C18.2098 5.84117 19 7.61009 19 9.45455Z"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3 10C13.3 10.9941 12.4941 11.8 11.5 11.8C10.5059 11.8 9.7 10.9941 9.7 10C9.7 9.00589 10.5059 8.2 11.5 8.2C12.4941 8.2 13.3 9.00589 13.3 10Z"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 22.5H21"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StationsIcon;
