/* eslint-disable max-len */
import React from "react";

const DropdownIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2953 15.3248L7.05532 11.0848C6.96159 10.9919 6.8872 10.8813 6.83643 10.7594C6.78566 10.6375 6.75952 10.5068 6.75952 10.3748C6.75952 10.2428 6.78566 10.1121 6.83643 9.99024C6.8872 9.86838 6.96159 9.75778 7.05532 9.66482C7.24268 9.47856 7.49613 9.37402 7.76032 9.37402C8.02451 9.37402 8.27796 9.47857 8.46532 9.66482L12.0053 13.2048L15.5453 9.66482C15.7327 9.47857 15.9861 9.37402 16.2503 9.37402C16.5145 9.37402 16.768 9.47857 16.9553 9.66482C17.048 9.75826 17.1213 9.86907 17.1711 9.99091C17.2209 10.1127 17.2461 10.2432 17.2453 10.3748C17.2461 10.5064 17.2209 10.6369 17.1711 10.7587C17.1213 10.8806 17.048 10.9914 16.9553 11.0848L12.7153 15.3248C12.6224 15.4185 12.5118 15.4929 12.3899 15.5437C12.268 15.5945 12.1373 15.6206 12.0053 15.6206C11.8733 15.6206 11.7426 15.5945 11.6207 15.5437C11.4989 15.4929 11.3883 15.4185 11.2953 15.3248Z"
      fill="white"
    />
  </svg>
);

export default DropdownIcon;
