/* eslint-disable max-len */
import React from "react";

const PowerGridIcon: React.FC = () => (
  <svg
    fill="#9B9B9B"
    height="200px"
    width="200px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512 512"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <g>
        <g>
          <path d="M439.652,0c-18.413,0-33.391,14.978-33.391,33.391c0,12.305,6.764,22.955,16.696,28.748v38.035h-59.083l-7.885-52.566 c-1.229-8.173-8.25-14.217-16.511-14.217H172.522c-8.261,0-15.282,6.044-16.511,14.217l-7.885,52.566H89.044V62.139 c9.932-5.792,16.696-16.443,16.696-28.748C105.739,14.978,90.761,0,72.348,0S38.957,14.978,38.957,33.391 c0,12.305,6.764,22.955,16.696,28.748v54.731c0,9.223,7.479,16.696,16.696,16.696h70.77 c-19.581,130.539-31.349,208.987-51.755,345.043H72.348c-9.217,0-16.696,7.473-16.696,16.696c0,9.223,7.479,16.696,16.696,16.696 h66.783c9.217,0,16.696-7.473,16.696-16.696c0-9.223-7.479-16.696-16.696-16.696h-14.004l5.01-33.391h251.728l5.01,33.391H372.87 c-9.217,0-16.696,7.473-16.696,16.696c0,9.223,7.479,16.696,16.696,16.696h66.783c9.217,0,16.696-7.473,16.696-16.696 c0-9.223-7.479-16.696-16.696-16.696h-19.014c-3.268-21.788-29.717-198.118-32.992-219.952c-0.007-0.036-0.01-0.071-0.017-0.107 l-18.747-124.985h70.77c9.217,0,16.696-7.473,16.696-16.696V62.139c9.932-5.792,16.696-16.443,16.696-28.748 C473.043,14.978,458.065,0,439.652,0z M348.954,225.82l-63.644-44.128l51.652-35.813L348.954,225.82z M194.187,244.87 l61.818-42.86l61.817,42.86H194.187z M315.153,278.261l-59.046,38.593l-59.157-38.593H315.153z M256.004,161.376l-40.111-27.81 h80.223L256.004,161.376z M186.902,66.783h138.195l5.009,33.391H181.894L186.902,66.783z M175.038,145.872l51.663,35.82 l-63.656,44.135L175.038,145.872z M153.456,289.752l72.122,47.053l-87.788,57.377L153.456,289.752z M171.815,411.826 l84.302-55.099l84.456,55.099H171.815z M374.163,393.874l-87.522-57.098l71.907-46.997L374.163,393.874z" />
        </g>
      </g>
    </g>
  </svg>
);

export default PowerGridIcon;
