/* eslint-disable max-len */
import React from "react";

const SubscriptionIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 4.5H16C16.5304 4.5 17.0391 4.72242 17.4142 5.11833C17.7893 5.51424 18 6.05121 18 6.61111V21.3889C18 21.9488 17.7893 22.4858 17.4142 22.8817C17.0391 23.2776 16.5304 23.5 16 23.5H8C7.46957 23.5 6.96086 23.2776 6.58579 22.8817C6.21071 22.4858 6 21.9488 6 21.3889V6.61111C6 6.05121 6.21071 5.51424 6.58579 5.11833C6.96086 4.72242 7.46957 4.5 8 4.5H10"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 8.5H21C21.5304 8.5 22.0391 8.71071 22.4142 9.08579C22.7893 9.46086 23 9.96957 23 10.5V20.5C23 21.0304 22.7893 21.5391 22.4142 21.9142C22.0391 22.2893 21.5304 22.5 21 22.5H20C19.4696 22.5 18 22.5 18 22.5C18 22.5 18 22.5 18 20.5V10.5C18 9.96957 18.2107 9.46086 18.5858 9.08579C18.9609 8.71071 19.4696 8.5 20 8.5"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 8.5H4C4.53043 8.5 5.03914 8.71071 5.41421 9.08579C5.78929 9.46086 6 9.96957 6 10.5V20.5C6 22 6 22.5 6 22.5C6 22.5 4.53043 22.5 4 22.5H3C2.46957 22.5 1.96086 22.2893 1.58579 21.9142C1.21071 21.5391 1 21.0304 1 20.5V10.5C1 9.96957 1.21071 9.46086 1.58579 9.08579C1.96086 8.71071 2.46957 8.5 3 8.5Z"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubscriptionIcon;
