/* eslint-disable max-len */
import React from "react";

const BellIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6386 9.77403C17.6386 8.32716 17.0638 6.93955 16.0407 5.91646C15.0176 4.89337 13.63 4.3186 12.1831 4.3186C10.7363 4.3186 9.34866 4.89337 8.32557 5.91646C7.30248 6.93955 6.72771 8.32716 6.72771 9.77403C6.72771 16.1387 4 17.9572 4 17.9572H20.3663C20.3663 17.9572 17.6386 16.1387 17.6386 9.77403Z"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7561 21.594C13.5962 21.8696 13.3668 22.0983 13.0907 22.2573C12.8147 22.4163 12.5017 22.5 12.1831 22.5C11.8645 22.5 11.5515 22.4163 11.2755 22.2573C10.9994 22.0983 10.77 21.8696 10.6101 21.594"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BellIcon;
