import { Switch } from "@headlessui/react";
import React from "react";

export interface PropsTypes {
  on?: boolean;
  onClick?: () => void;
}

const ToggleSwitch: React.FC<PropsTypes> = (props): JSX.Element => {
  const classNames = (...classes: string[]) =>
    classes.filter(Boolean).join(" ");

  return (
    <Switch
      checked={props.on}
      onChange={props.onClick}
      className={classNames(
        props.on ? "bg-primary-main" : "bg-gray-200",
        "relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          props.on ? "translate-x-6" : "translate-x-0",
          "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
};

ToggleSwitch.defaultProps = {
  on: undefined,
  onClick: () => null,
};

export default ToggleSwitch;
