/* eslint-disable max-len */
import React from "react";

const FleetIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12.5H19"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 17.5V19C18 19.1326 17.9342 19.2598 17.8169 19.3536C17.6997 19.4473 17.5408 19.5 17.375 19.5H15.5C15.3342 19.5 15.1753 19.4473 15.0581 19.3536C14.9408 19.2598 14.875 19.1326 14.875 19V17.5M9.125 17.5V19C9.125 19.1326 9.05915 19.2598 8.94194 19.3536C8.82473 19.4473 8.66576 19.5 8.5 19.5H6.625C6.45924 19.5 6.30027 19.4473 6.18306 19.3536C6.06585 19.2598 6 19.1326 6 19V17.5"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5H9"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 14.5H16"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 12.7941L16.132 7.84933C16.0927 7.74535 16.0286 7.65701 15.9475 7.595C15.8664 7.533 15.7718 7.5 15.6751 7.5H8.32494C8.22822 7.5 8.13358 7.533 8.05248 7.595C7.97138 7.65701 7.90731 7.74535 7.86803 7.84933L6 12.7941V17.5H18V12.7941Z"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 12.5H23"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0939 17.5V19C23.0939 19.1326 23.028 19.2598 22.9108 19.3536C22.7936 19.4473 22.6346 19.5 22.4689 19.5H20.5939C20.4281 19.5 20.2691 19.4473 20.1519 19.3536C20.0347 19.2598 19.9689 19.1326 19.9689 19V17.5"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 7.5H20.1877C20.3086 7.5 20.4269 7.533 20.5282 7.595C20.6296 7.65701 20.7097 7.74535 20.7588 7.84933L23.0938 12.7941V17.5H20.0938"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.26074 12.5H1.10127"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.999964 17.5V19C0.999964 19.1326 1.07106 19.2598 1.19762 19.3536C1.32417 19.4473 1.49582 19.5 1.6748 19.5H3.69931C3.87829 19.5 4.04993 19.4473 4.17649 19.3536C4.30305 19.2598 4.37415 19.1326 4.37415 19V17.5"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 7.5H4.1379C4.00737 7.5 3.87963 7.533 3.77018 7.595C3.66072 7.65701 3.57425 7.74535 3.52123 7.84933L1 12.7941V17.5H4.23921"
      stroke="#9B9B9B"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FleetIcon;
