/* eslint-disable max-len */
import React from "react";

const CollapsedSidebarIcon: React.FC = () => (
  <svg
    width="33"
    height="44"
    viewBox="0 0 33 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4939 37.4904C10.4939 41.0854 13.4083 44.0002 17.0033 44.0002C20.5983 44.0002 23.5127 41.0854 23.5127 37.4904C23.5127 33.8953 20.5983 30.9805 17.0033 30.9805C13.4083 30.9805 10.4939 33.8953 10.4939 37.4904Z"
      fill="#50C878"
    />
    <path
      d="M16.8062 30.9819C23.9962 30.9819 29.825 25.1523 29.825 17.9622H16.8062L16.8062 30.9819Z"
      fill="#50C878"
    />
    <path
      d="M3.78748 17.9618C3.78748 25.1519 9.61619 30.9816 16.8063 30.9816L16.8063 17.9618H3.78748Z"
      fill="#50C878"
    />
    <path
      d="M16.8062 17.9631H29.825C29.825 10.773 23.9962 4.94336 16.8062 4.94336L16.8062 17.9631Z"
      fill="#50C878"
    />
    <path
      d="M3.78735 17.9631H16.8062L16.8062 4.94336C9.61607 4.94336 3.78735 10.773 3.78735 17.9631Z"
      fill="#50C878"
    />
    <path
      d="M21.248 17.4521L17.2533 26.7731C17.1911 26.9119 17.0834 27.0253 16.9481 27.0946C16.8128 27.1639 16.6579 27.1851 16.5089 27.1545C16.36 27.1239 16.2259 27.0435 16.1288 26.9265C16.0318 26.8095 15.9775 26.6628 15.9749 26.5108V20.5187H12.646C12.536 20.5181 12.4278 20.4904 12.3311 20.4381C12.2344 20.3857 12.1521 20.3102 12.0915 20.2184C12.0309 20.1266 11.9939 20.0213 11.9838 19.9118C11.9737 19.8023 11.9907 19.6919 12.0335 19.5906L16.0282 10.2695C16.0884 10.1282 16.1956 10.012 16.3316 9.94074C16.4676 9.86943 16.6241 9.84734 16.7746 9.87822C16.925 9.90909 17.0602 9.99102 17.1571 10.1101C17.2541 10.2293 17.3069 10.3782 17.3065 10.5318V16.5239H20.6355C20.7455 16.5245 20.8536 16.5522 20.9504 16.6046C21.0471 16.6569 21.1294 16.7324 21.19 16.8242C21.2506 16.916 21.2876 17.0213 21.2977 17.1308C21.3078 17.2404 21.2908 17.3507 21.248 17.4521Z"
      fill="white"
    />
  </svg>
);

export default CollapsedSidebarIcon;
