import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "tailwindcss/tailwind.css";
import Header from "../components/CommonSection/Header/Header";
import SideBar from "../components/SideBar/SideBar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { RootState } from "../Store/Store";
import classes from "./Layout.module.scss";

interface PropsTypes {
  children: JSX.Element;
  pageName?: string;
}

const Layout: React.FC<PropsTypes> = (props): JSX.Element => {
  const { width } = useWindowDimensions();
  const [menuCollapse, setmenuCollapse] = useState<boolean>(true);

  useEffect(() => {
    if (width && width <= 1200) {
      setmenuCollapse(true);
    }
  }, [width]);

  const loader = useSelector((state: RootState) => state.master.loader);

  return (
    <>
      <Header
        title={props.pageName}
        menuCollapse={menuCollapse}
        setmenuCollapse={setmenuCollapse}
      />
      <SideBar menuCollapse={menuCollapse} />
      {loader && (
        <div className="loader-container">
          <div className="spinner" />
        </div>
      )}
      <div
        className={`${classes.content_body} ${
          menuCollapse ? classes.left_menu_close : classes.left_menu_open
        }`}
      >
        {props.children}
      </div>
    </>
  );
};

Layout.defaultProps = {
  pageName: undefined,
};

export default Layout;
