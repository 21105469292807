/* eslint-disable max-len */
import React from "react";

const LogoutIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.26355 3.09106H4.26355C3.73312 3.09106 3.22441 3.30178 2.84934 3.67685C2.47426 4.05192 2.26355 4.56063 2.26355 5.09106V19.0911C2.26355 19.6215 2.47426 20.1302 2.84934 20.5053C3.22441 20.8804 3.73312 21.0911 4.26355 21.0911H8.26355"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2635 17.0911L20.2635 12.0911L15.2635 7.09106"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2635 12.0911H8.26355"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogoutIcon;
