/* eslint-disable max-len */
import React from "react";

const UserIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 21.8771V19.8771C20 18.8162 19.5786 17.7988 18.8284 17.0486C18.0783 16.2985 17.0609 15.8771 16 15.8771H8C6.93913 15.8771 5.92172 16.2985 5.17157 17.0486C4.42143 17.7988 4 18.8162 4 19.8771V21.8771"
      stroke="#9B9B9B"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11.8771C14.2091 11.8771 16 10.0862 16 7.87708C16 5.66794 14.2091 3.87708 12 3.87708C9.79086 3.87708 8 5.66794 8 7.87708C8 10.0862 9.79086 11.8771 12 11.8771Z"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserIcon;
