/* eslint-disable @typescript-eslint/no-use-before-define */
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import ReactToolTip from "react-tooltip";
import { useDispatch } from "react-redux";
import ChargerIcon from "../../Icons/ChargerIcon";
import CmsIcon from "../../Icons/CmsIcon";
import CollapsedSidebarIcon from "../../Icons/CollapsedSidebarIcon";
import DropdownIcon from "../../Icons/DropdownIcon";
import FeedbackIcon from "../../Icons/FeedbackIcon";
import FleetIcon from "../../Icons/FleetIcon";
import HomeIcon from "../../Icons/HomeIcon";
import IntuChargeLogoLight from "../../Icons/IntuChargeLogoLight";
import LogoutIcon from "../../Icons/LogoutIcon";
import SettingsIcon from "../../Icons/SettingsIcon";
import StationsIcon from "../../Icons/StationsIcon";
import SubscriptionIcon from "../../Icons/SubscriptionIcon";
import classes from "./SideBar.module.scss";
import { logout as logoutAction } from "../../Store/Slices/AuthSlice";
import { clearStorage } from "../../utils/general";
import UserIcon from "../../Icons/UserIcon";
import { clearMaster } from "../../Store/Slices/MasterSlice";
import PowerGridIcon from "../../Icons/PowerGridIcon";

const SideBar: React.FC<{ menuCollapse: boolean }> = (props): JSX.Element => {
  const [allowSubMenu, setAllowSubMenu] = useState(true);
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    if (props.menuCollapse) {
      setAllowSubMenu(false);
    } else {
      setAllowSubMenu(true);
    }
  }, [props.menuCollapse]);

  const MenuItems = [
    {
      name: "Dashboard",
      link: "/",
      subLinks: ["/"],
      icon: <HomeIcon />,
    },
    {
      name: "Request Management",
      link: "/request-management",
      subLinks: ["/request-management", "/request-details/[id]"],
      icon: <StationsIcon />,
    },
    {
      name: "Employees",
      link: "/employee",
      subLinks: ["/employee"],
      icon: <UserIcon />,
    },
    {
      name: "Fleet Management",
      link: "#",
      subLinks: [
        "/list-of-fleets",
        "/fleet-subscriptions-list",
        "/fleet-details",
      ],
      icon: <FleetIcon />,
      subtab: [
        {
          name: "List of Fleets",
          link: "/list-of-fleets",
          subLinks: ["/list-of-fleets", "/fleet-details"],
          icon: <>&#9675;</>,
        },
        {
          name: "Fleet Subscriptions List",
          link: "/fleet-subscriptions-list",
          subLinks: ["/fleet-subscriptions-list"],
          icon: <>&#9675;</>,
        },
      ],
    },
    {
      name: "Chargers",
      link: "/chargers",
      subLinks: ["/chargers"],
      icon: <ChargerIcon />,
    },
    {
      name: "Grid Type",
      link: "/grid-type",
      subLinks: ["/grid-type"],
      icon: <PowerGridIcon />,
    },
    {
      name: "Feedback",
      link: "#",
      subLinks: ["/feedback-list", "/feedback-category", "/feedback-assignee"],
      icon: <FeedbackIcon />,
      subtab: [
        {
          name: "List",
          link: "/feedback-list",
          subLinks: ["/feedback-list"],
          icon: <>&#9675;</>,
        },
        {
          name: "Category",
          link: "/feedback-category",
          subLinks: ["/feedback-category"],
          icon: <>&#9675;</>,
        },
      ],
    },
    {
      name: "Subscription",
      link: "/subscription",
      subLinks: ["/subscription", "/history-of-subscription"],
      icon: <SubscriptionIcon />,
    },
    {
      name: "Settings",
      link: "/setting",
      subLinks: ["/setting"],
      icon: <SettingsIcon />,
    },
    {
      name: "CMS page",
      link: "/cms",
      subLinks: ["/cms"],
      icon: <CmsIcon />,
    },
  ];
  return (
    <div
      className={`${classes.sidebar_section} ${
        props.menuCollapse ? "w-[79px]" : "w-[300px]"
      } z-30`}
    >
      <ProSidebar
        collapsed={props.menuCollapse}
        className={`${classes.sidebar_main}`}
      >
        <SidebarHeader>
          <div
            className={`${classes.sidebar_logo} ${
              props.menuCollapse ? " ml-0 w-[79px]" : "-mt-2 ml-0 w-[271px]"
            }`}
          >
            {props.menuCollapse ? (
              <CollapsedSidebarIcon />
            ) : (
              <IntuChargeLogoLight />
            )}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <div className="mt-[79px]">
            <Menu>
              <li
                className={`ml-2 ${
                  props.menuCollapse ? "w-[75%]" : " w-[95%]"
                }`}
              >
                {MenuItems.map((item: any, i: number) => (
                  <ListItem
                    allowSubMenu={allowSubMenu}
                    item={item}
                    key={i}
                    menuCollapse={props.menuCollapse}
                  />
                ))}
                <div
                  className="rounded pl-3"
                  role="button"
                  aria-hidden="true"
                  onClick={() => {
                    clearStorage();
                    dispatch(clearMaster());
                    dispatch(logoutAction());
                    router.push("/login");
                  }}
                  data-for="sidebar-tooltip-logout"
                  data-tip="Logout"
                >
                  <ul>
                    <MenuItem icon={<LogoutIcon />}>
                      <div className={`${classes.sidebar_menulink}`}>
                        <span>Logout</span>
                      </div>
                    </MenuItem>
                  </ul>
                    {props.menuCollapse && (
                      <ReactToolTip
                        aria-haspopup="true"
                        id="sidebar-tooltip-logout"
                        effect="solid"
                        place="right"
                        textColor="#fff"
                        backgroundColor="#060F17"
                      />
                    )}
                </div>
              </li>
            </Menu>
          </div>
        </SidebarContent>
        {!props.menuCollapse && (
          <SidebarFooter className="border-0">
            <div className={`${classes.sidebar_footer}`}>
              &copy; {new Date().getFullYear()} IntuCharge, Inc. <br />
              All rights reserved.
            </div>
          </SidebarFooter>
        )}
      </ProSidebar>
    </div>
  );
};

const ListItem = (props: any) => {
  const router = useRouter();
  const { item, allowSubMenu, menuCollapse } = props;
  const [open, setOpen] = useState(
    !!(item.subLinks.indexOf(router.pathname) !== -1 && allowSubMenu)
  );
  return (
    <>
      <div
        role="button"
        aria-hidden="true"
        className={`rounded pl-3 ${
          item.subLinks.indexOf(router.pathname) !== -1
            ? "bg-font-textcolor2 text-white"
            : ""
        }`}
        onClick={() => {
          setOpen(!open);
        }}
        data-for={`icon-${item.name}`}
        data-tip={item.name}
      >
        <ul>
          <MenuItem icon={item.icon}>
            <div className={`${classes.sidebar_menulink}`}>
              {item.subtab ? (
                <span>{item.name}</span>
              ) : (
                <Link href={item.link}>{item.name}</Link>
              )}
              {item.subtab && !props.menuCollapse && (
                <span className={`${open ? "" : "-rotate-90"}`}>
                  <DropdownIcon />
                </span>
              )}
            </div>
          </MenuItem>
        </ul>
      </div>
      {menuCollapse && (
        <ReactToolTip
          aria-haspopup="true"
          id={`icon-${item.name}`}
          effect="solid"
          place="right"
          textColor="#fff"
          backgroundColor="#060F17"
        />
      )}
      {menuCollapse && item.subtab && (
        <div className="overflow-visible">
          <ReactToolTip
            event="click"
            globalEventOff="click"
            id={`icon-${item.name}`}
            backgroundColor="#060F17"
            place="right"
            effect="solid"
            clickable
          >
            {item.subtab &&
              item.subtab.map((subitem: any, i: number) => (
                <div key={i} className="-ml-5">
                  <ul>
                    <MenuItem icon={subitem.icon}>
                      <div className={`${classes.sidebar_submenulink}`}>
                        <Link href={subitem.link}>{subitem.name}</Link>
                      </div>
                    </MenuItem>
                  </ul>
                </div>
              ))}
          </ReactToolTip>
        </div>
      )}
      {item.subtab &&
        open &&
        allowSubMenu &&
        item.subtab.map((subitem: any, i: number) => (
          <div
            key={i}
            className={`rounded pl-3 ${
              subitem.subLinks.indexOf(router.pathname) !== -1 ? "" : ""
            }`}
          >
            <ul>
              <MenuItem icon={subitem.icon}>
                <div className={`${classes.sidebar_menulink}`}>
                  <Link href={subitem.link}>{subitem.name}</Link>
                </div>
              </MenuItem>
            </ul>
          </div>
        ))}
    </>
  );
};

export default SideBar;
