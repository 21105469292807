/* eslint-disable max-len */
import React from "react";

const HomeIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1396_39675)">
      <path
        d="M2.6311 9.63281L11.6311 2.63281L20.6311 9.63281V20.6328C20.6311 21.1632 20.4204 21.672 20.0453 22.047C19.6702 22.4221 19.1615 22.6328 18.6311 22.6328H4.6311C4.10067 22.6328 3.59196 22.4221 3.21689 22.047C2.84182 21.672 2.6311 21.1632 2.6311 20.6328V9.63281Z"
        stroke="#9B9B9B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6311 22.6328V12.6328H14.6311V22.6328"
        stroke="#9B9B9B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1396_39675">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HomeIcon;
